import './Reward.css'
import useRefresh from '../../hooks/useRefresh';
import {Button } from "@material-ui/core";
import { ethers } from "ethers";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import { contractAddress, contractAbi } from "../../contracts";
import React, {useEffect} from 'react';

let connectedAddress = null;
let signer = null;

export default function RewardToken(wallet) {
  signer = useSigner();
  const [tokenBalance, setTokenBalance] = React.useState('0');
  const [tokenBalanceFormatted, setTokenBalanceFormatted] = React.useState('0');
  const [totalRewardAmount, setTotalRewardAmount] = React.useState('0');
  const [claimableAmount, setClaimableAmount] = React.useState('0');
  // const [minimumOutput, setMinimumOutput] = React.useState('');
  const {fastRefresh} = useRefresh();
  let contract = new ethers.Contract(contractAddress, JSON.parse(contractAbi), signer);

  const handleClaimETH = async() => {
    try {
      const transaction = await contract.claimEth();

      await transaction.wait().then((result) => {
        console.log("Claim successful!");
      });
    } catch(ex) {
      console.log(ex);
    }
  }

  const handleClaimCBETH = async() => {
    try {
      const transaction = await contract.claimCBETH(0);

      await transaction.wait().then((result) => {
        console.log("Claim successful!");
      });
    } catch(ex) {
      console.log(ex);
    }
  }

  const handleClaimCBBTC= async() => {
    try {
      const transaction = await contract.claimCBBTC(0);

      await transaction.wait().then((result) => {
        console.log("Claim successful!");
      });
    } catch(ex) {
      console.log(ex);
    }
  }

  const handleCompound= async() => {
    try {
      const transaction = await contract.compound(0);

      await transaction.wait().then((result) => {
        console.log("Claim successful!");
      });
    } catch(ex) {
      console.log(ex);
    }
  }

  useEffect(()=>{    
    const update = async () => {
      if(signer){
          try {
            const v2HoldingData = await contract.balanceOf(connectedAddress);
            
            setTokenBalance(parseFloat(ethers.utils.formatEther(v2HoldingData)).toFixed(0));
            setTokenBalanceFormatted(parseFloat(ethers.utils.formatEther(v2HoldingData)).toLocaleString());

            const claimAmount = await contract.withdrawableDividendOf(connectedAddress);
            let formattedClaimableAmount = ethers.utils.formatEther(claimAmount);
            setClaimableAmount(parseFloat(formattedClaimableAmount).toFixed(8));

            const claimedAmount = await contract.getAccountDividendsInfo(connectedAddress);
            console.log(claimedAmount);
            let formattedClaimedAmount = ethers.utils.formatEther(claimedAmount[2]);
            setTotalRewardAmount(parseFloat(formattedClaimedAmount).toFixed(8));
            
          } catch (err) {
              console.log(err); 
          }
      }
    }
    update();
  },[fastRefresh, signer, contract]);

  signer = useSigner();
  connectedAddress = useAddress();

  

  return (
    
    <div className='overwrap'>
        <div class='box1'>
            <div style={{color:"#7c7c7c", fontSize:"18px", font:"Satoshi Medium", textAlign:"left"}}>   
                hold $sphere to start earning.
            </div>
            <div style={{color:"#FFFFFF", fontWeight:"bolder", fontSize:"48px", font:"Satoshi Medium", textAlign:"left"}}>
                sphere rewards dapp.
            </div>
            <br />
            <div style={{color:"#FFFFFF", fontSize:"14px", font:"Satoshi Medium", textAlign:"left"}}>
                by simply holding $sphere, you unlock access to a constant <u>stream of rewards</u>. choose to claim your rewards as cbBTC, cbETH, sphere, or ETH directly through the dapp. the more $sphere tokens you hold, the greater your share of the rewards, as earnings are directly proportional to your holdings.
            </div>
            <br />
            <div className='content'> 
              <div>
                  <span style={{color:"#FFFFFF", fontWeight:"bolder", fontSize:"48px", font:"Satoshi Medium"}}>tokens held:</span><br />
                  <span style={{color:"#236bd2", fontWeight:"bolder", fontSize:"48px", font:"Satoshi Medium"}}>{tokenBalanceFormatted} $sphere.</span>
              </div>
            </div>
            <br />
            <div className='content'>
              {claimableAmount > 0 ?
              <div>
                <span style={{color:"#FFFFFF", fontWeight:"bolder", fontSize:"48px", font:"Satoshi Medium"}}>eth available to claim:</span><br />
                  <span style={{color:"#236bd2", fontWeight:"bolder", fontSize:"48px", font:"Satoshi Medium"}}>{`${claimableAmount}`} ETH</span>
                </div>
                :
                <div>
                </div>
              }
            </div>
        </div>
        <br />
        <div class='box2'>
            <div>
              <br />
              <div className='btn btn-primary' onClick={handleClaimCBBTC} style={{ backgroundColor: 'rgb(231,117,34,0.69)', maxWidth:'461px', height:'88px' }}>
                <Button fullwidth style={{  backgroundColor: 'rgb(231,117,34,0)'}}>
                  claim as cbBTC. →  
                </Button>
              <img alt="CbBTC" src="https://static.wixstatic.com/media/d4f8f4_d49ca036eff9461b955387b253dc21b2~mv2.png" height='102px' width='102px'></img>
              </div>
            </div>
            <div>
              <br />
              <div className='btn btn-primary'  onClick={handleClaimCBETH} style={{ backgroundColor: 'rgb(134,122,182,0.69)', maxWidth:'461px', height:'88px' }}>
                <Button style={{ backgroundColor: 'rgb(134,122,182,0)', textAlign: 'left'}}>
                  claim as cbETH. → 
                </Button>
                <img alt="CbETH" src="https://static.wixstatic.com/media/d4f8f4_54fce7986f874170a2e42823b9b7aea2~mv2.png" height='102px' width='102px'></img>
              </div>
            </div>
            <div>
              <br />
              <div className='btn btn-primary' onClick={handleCompound} style={{ backgroundColor: 'rgb(35,107,210,0.69)', maxWidth:'461px', height:'88px' }}>
              <Button style={{ backgroundColor: 'rgb(35,107,210,0)' }}>
                claim as sphere. → 
              </Button>
              <img alt="sphere" src="https://static.wixstatic.com/media/d4f8f4_f99b0158611f4139a73448c9ef6851b4~mv2.png" height='102px' width='102px'></img>
              </div>
            </div>
            <div>
              <br />
              <div className='btn btn-primary' onClick={handleClaimETH} style={{ backgroundColor: 'rgb(140,140,140,0.69)', maxWidth:'461px', height:'88px' }}>
              <Button  style={{ backgroundColor: 'rgb(140,140,140,0)' }}>
                claim as ETH. →
              </Button>
              <img alt="ETH" src="https://static.wixstatic.com/media/d4f8f4_d0cd8ac8e58246b7a56273db6b8a4b5a~mv2.png" height='94px' width='94px'></img>
              </div>
            </div>
            <div className='content'> 
            <br />
            <div>
                <span style={{color:"#FFFFFF", fontWeight:"bolder", fontSize:"24px"}}><center>total value (in eth) (claimed + unclaimed):</center></span>
                <span style={{color:"#236bd2", fontWeight:"bolder", fontSize:"48px"}}>{totalRewardAmount} ETH</span>
            </div>
          </div>
            </div>
      <br />
      
      <br />     
      
    </div>
  )
}

  