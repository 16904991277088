import './Header.css'
import {Button } from "@material-ui/core";
import { ConnectWallet } from "@thirdweb-dev/react";
import { useSigner } from "@thirdweb-dev/react";
import RewardToken from "../RewardToken";
export default function Header(props) {
    var signer = useSigner();

    return(
        <div style={{ backgroundImage: `url('https://static.wixstatic.com/media/d4f8f4_f8a1a468990e423f9ae347ddbaef0651~mv2.png')`, backgroundColor: 'black', backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment:'fixed'}}>
            <header>
                <div class="container container-xxl">
                    <nav>
                        <div className="brand">
                            <img alt='' src='https://static.wixstatic.com/media/d4f8f4_7df8e849132c4d67a7b72a695a2eda0d%7Emv2.png/v1/fill/w_192%2Ch_192%2Clg_1%2Cusm_0.66_1.00_0.01/d4f8f4_7df8e849132c4d67a7b72a695a2eda0d%7Emv2.png' />
                        </div>
                        <div className='nav-cta d-flex align-center ml-auto'>
                            <ConnectWallet style={{ width:"128px",height:"64px" }} switchToActiveChain={true} className="btn btn-primary" theme="dark" dropdownPosition={{ side: 'bottom', align: 'center'}} />
                        </div>
                    </nav>
                </div>
            </header>
            <main class="mt-10">
            <div>
            
                {signer != null ?
                    <div className='container container-sm text-center mt-10 full-width full-height'>
                        <RewardToken/>
                    </div>: 
                    <div className='container container-sm text-center mt-10 full-width full-height'>
                        <div style={{color:"#7c7c7c", fontSize:"18px", font:"Satoshi Medium", textAlign:"left"}}>   
                            hold $sphere to start earning.
                        </div>
                        <div style={{color:"#FFFFFF", fontWeight:"bolder", fontSize:"48px", font:"Satoshi Medium", textAlign:"left"}}>
                            sphere rewards dapp.
                        </div>
                        <br />
                        <div style={{color:"#FFFFFF", fontSize:"14px", font:"Satoshi Medium", textAlign:"left"}}>
                            by simply holding $sphere, you unlock access to a constant <u>stream of rewards</u>. choose to claim your rewards as cbBTC, cbETH, sphere, or ETH directly through the dapp. the more $sphere tokens you hold, the greater your share of the rewards, as earnings are directly proportional to your holdings.
                        </div>
                        <br />
                        <br />
                        <h3> Connect Wallet to Claim</h3>
                    </div>
                    
                }
                <div style={{float:"right", paddingRight:"64px"}}>
                    <Button style={{ backgroundColor: 'rgb(255,255,255,1)' }} href="https://www.spherebase.io/">
                        <span style={{color:"#000000", fontWeight:"bolder", fontSize:"16px", font:"Satoshi", textAlign:"left"}}>back to website</span>
                    </Button>
                </div>
            </div>
            
            </main>
            
            <footer className="mt-10">
                <div className="container">
                    <p class="no-m text-center">&copy; 2024 spherebase.io - All rights reserved</p>
                </div>
            </footer>
        </div>
    );
}