import Header from './components/Header/index'
import { ThirdwebProvider } from "@thirdweb-dev/react";
import './App.css';

function App() {

  return (
    <>
      <ThirdwebProvider
        activeChain={"base"}
        clientId="45fee432f679b5bb1df39c2e8d7af9a1"
      >
        <Header />
      </ThirdwebProvider>
    </>
  );
}

export default App;
